// angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ngrx
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// components
import { ProfileWidgetComponent } from './components/widgets/profile-widget/profile-widget.component';
import { LogoutComponent } from './components/dialog/logout/logout.component';
import { TwoFactorAuthSetupComponent } from './components/dialog/two-factor-auth-setup/two-factor-auth-setup.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { RegisterComponent } from './components/register/register.component';
import { UserProfileCardComponent } from './components/user-profile-card/user-profile-card.component';
import { RemoveUserDialogComponent } from './components/dialog/remove-user-dialog/remove-user-dialog.component';
import { UpsertUserDialogComponent } from './components/dialog/upsert-user-dialog/upsert-user-dialog.component';
// config
import { OnboardingModuleConfiguration } from './types';
import { FEATURE_KEY } from './config';
import { metaReducers, ROOT_REDUCER } from './store';
// effects
import { LoginEffects } from './store/effects/login.effects';
import { PasswordEffects } from './store/effects/password.effects';
import { PreserverEffects } from './store/effects/preserver.effect';
import { TwoFactorEffects } from './store/effects/two-factor.effects';
import { UserEffects } from './store/effects/user.effects';
import { HttpClientModule } from '@angular/common/http';
// ca modules
import { SnackbarModule } from '@ca/ca-snackbar';
import { CaAreYouSureModule } from '@ca/ca-are-you-sure';
import { CaNgCoreModule } from '@ca/ca-ng-core';
// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCommonModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { UserSummaryGridComponent } from './components/dumb/user-summary-grid/user-summary-grid.component';

//#region ANGULAR MATERIAL
const MAT_MODULES = [
  MatFormFieldModule,
  MatTooltipModule,
  MatInputModule,
  MatIconModule,
  MatTabsModule,
  MatDialogModule,
  MatButtonModule,
  MatTableModule,
  MatSlideToggleModule,
  MatCardModule,
  MatDividerModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatSelectModule,
  MatCheckboxModule,
  MatGridListModule,
  MatMenuModule,
];
//#endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatCommonModule,
    ...MAT_MODULES,
    SnackbarModule,
    CaAreYouSureModule,
    CaNgCoreModule,
    HttpClientModule,

    StoreModule.forFeature(FEATURE_KEY, ROOT_REDUCER, {
      metaReducers,
    }),
    EffectsModule.forFeature([
      TwoFactorEffects,
      LoginEffects,
      PasswordEffects,
      PreserverEffects,
      UserEffects,
    ]),
  ],
  declarations: [
    LoginComponent,
    UsersTableComponent,
    SetPasswordComponent,
    ResetPasswordComponent,
    ProfileWidgetComponent,
    LogoutComponent,
    TwoFactorAuthSetupComponent,
    EditProfileComponent,
    RegisterComponent,
    UserProfileCardComponent,
    UpsertUserDialogComponent,
    RemoveUserDialogComponent,
    UserSummaryGridComponent,
  ],
  exports: [
    LoginComponent,
    UsersTableComponent,
    SetPasswordComponent,
    ResetPasswordComponent,
    ProfileWidgetComponent,
    LogoutComponent,
    TwoFactorAuthSetupComponent,
    EditProfileComponent,
    RegisterComponent,
    UserProfileCardComponent,
    UpsertUserDialogComponent,
    RemoveUserDialogComponent,
    UserSummaryGridComponent,
  ],
})
export class OnboardingModule {
  static forRoot(
    configuration: OnboardingModuleConfiguration = new OnboardingModuleConfiguration()
  ): ModuleWithProviders<OnboardingModule> {
    return {
      ngModule: OnboardingModule,
      providers: [
        {
          provide: OnboardingModuleConfiguration,
          useValue: configuration,
        },
      ],
    };
  }
}
