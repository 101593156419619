import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { OnboardingModule, OnboardingModuleConfiguration } from '@ca/onboarding';
import { KOLIBRI_ONBOARDING_MESSAGES, ONBOARDING_ROUTES_CONFIG } from '../config/onboarding.config';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ROOT_REDUCER, metaReducers } from './store';
import { SnackbarModule, SnackbarModuleConfiguration, SnackbarServiceConfiguration } from '@ca/ca-snackbar';
import { CA_ENVIRONMENT, CaNgCoreModule } from '@ca/ca-ng-core';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { AreYouSureOverlayConfiguration, CaAreYouSureModule, DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION } from '@ca/ca-are-you-sure';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//#region material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
//#endregion

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    SnackbarModule,
    CaNgCoreModule,
    CaAreYouSureModule,
    FormsModule,
    ReactiveFormsModule,

    //#region Angular Material Modules
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatBadgeModule,
    MatChipsModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    MatButtonModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule,
    MatGridListModule,
    MatDividerModule,
    MatSlideToggleModule,
    //#endregion
    OnboardingModule.forRoot(
      new OnboardingModuleConfiguration({
        messages: KOLIBRI_ONBOARDING_MESSAGES,
        routes: ONBOARDING_ROUTES_CONFIG,
      })
    ),
  ],
  providers: [
    {
      provide: SnackbarModuleConfiguration,
      useValue: new SnackbarModuleConfiguration(),
    },
    {
      provide: SnackbarServiceConfiguration,
      useValue: new SnackbarModuleConfiguration().service,
    },
    {
      provide: CA_ENVIRONMENT,
      useValue: environment,
    },

    {
      provide: AreYouSureOverlayConfiguration,
      useValue: DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
