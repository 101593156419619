import { Route, Routes } from '@angular/router';
import { ONBOARDING_APP_ROUTES, RegisterComponent, SetPasswordComponent, LoginComponent, ResetPasswordComponent, EditProfileComponent, AuthGuardService } from '@ca/onboarding';
import { HomeComponent } from './components/home/home.component';

const ONBOARDING_ROUTES: Routes = [
  {
    path: ONBOARDING_APP_ROUTES.REGISTER,
    component: RegisterComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.ACCESS,
    component: SetPasswordComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.LOGIN,
    component: LoginComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.RESETPASS,
    component: ResetPasswordComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.PROFILE,
    component: EditProfileComponent,
  },
  {
    path: 'access',
    redirectTo: ONBOARDING_APP_ROUTES.ACCESS,
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: ONBOARDING_APP_ROUTES.LOGIN,
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    redirectTo: ONBOARDING_APP_ROUTES.RESETPASS,
    pathMatch: 'full',
  },
];
export const appRoutes: Route[] = [
  ...ONBOARDING_ROUTES,
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
