// wrappers
export interface CaDataResponse<T, TypeName = string> {
  debug: boolean;
  type: TypeName;
  success: boolean;
  data: T;
}
export interface ApiResultBase<TypeName = string> extends ErrorsAndMessages {
  type: TypeName;
  status?: string;
  success: boolean;
  debug: boolean;

  /** @deprecated use 401 http response status */
  unauthorized?: boolean;
  /** only appended when set */
  deprecated?: boolean;
}
export interface PutResult<TypeName = string> extends ApiResultBase<TypeName> {
  id: number;
}
export interface DeleteRequest {
  id: number;
}
export type PostResult = ApiResultBase;
export interface DeleteResult extends ApiResultBase {
  id: number;
}
export interface ErrorsAndMessages {
  errors?: string[];
  messages?: string[];
}
