import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OnboardingModuleConfiguration, State } from '../types';
import { OnboardingService } from './onboarding.service';
import { CaEnvironment } from '@ca/ca-utils';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private configuration: OnboardingModuleConfiguration,
    private router: Router,
    private onboarding: OnboardingService,
    private logger: LoggingService,
    private store: Store<State>,
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    console.log('checking authorization');
    if (!sessionStorage.getItem(this.env.sessionStorageBearerKey)) {
      console.log('not authorized');
      this.router.navigate([this.configuration.appRoutes.login]);
      return false;
    } else {
      return this.onboarding
        .checkBearerToken()
        .then((res) => {
          if (!res) this.router.navigate([this.configuration.appRoutes.login]);
          console.log('authorized');
          return true;
        })
        .catch(() => {
          console.log('not authorized');
          this.router.navigate([this.configuration.appRoutes.login]);
          return false;
        });
    }
  }
}
