import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ReadyToBeNotified } from '@ca/ca-ng-core';
import { CaSubscriber } from '@ca/ca-utils';
import { DashboardUserData, ILinkedDivision, OnboardingService, OnboardingState, onboardingActions, selectDashboardUserData } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ca-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  title = 'kolibri';
  private sub: CaSubscriber;
  protected user$: Observable<any>;
  isLoggedIn = false;
  divisions: ILinkedDivision[] = [];
  activeDivision?: ILinkedDivision;

  constructor(private onboarding: OnboardingService, private router: Router, private store: Store<{ onboarding: OnboardingState }>) {
    this.sub = new CaSubscriber();

    this.user$ = this.store.select(selectDashboardUserData);
    this.sub.subscribe(this.user$, {
      next: (user: DashboardUserData) => {
        // console.log('user', user);
        this.isLoggedIn = user.isLoggedIn;
        this.divisions = user.userDivisions;

        if (user.activeDivision !== undefined && this.activeDivision === undefined) {
          this.store.dispatch(onboardingActions.loadUsers());

          // this.loadSettingsData();
          this.store.dispatch(ReadyToBeNotified());
        }
        this.activeDivision = user.activeDivision;
      },
    });
  }
}
