import { Component } from '@angular/core';
import { OnboardingState, onboardingActions } from '@ca/onboarding';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ca-home',
  template: ` <p>home works!</p>
    <button mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>`,
})
export class HomeComponent {
  constructor(private store: Store<{ onboarding: OnboardingState }>) {}
  logout() {
    this.store.dispatch(onboardingActions.logout());
  }
}
