import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromLoading from './reducers/loading.reducer';
import { environment } from '../../environments/environment';

export interface KolibriState {
  loading: fromLoading.LoadingState;
}

export const ROOT_REDUCER: ActionReducerMap<KolibriState> = {
  loading: fromLoading.reducer,
};

const log_prefix = '< Store >\n$';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...args: any): void => console.log(`${log_prefix}`, ...args);

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    log('state', state);
    log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<KolibriState>[] = !environment.production ? [] : [];
