import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { TwoFactorAuthSetupComponent } from '../../components';
import { TWO_FA_DIALOG_WIDTH } from '../../config';
import { OnboardingService } from '../../services';
import { ILoginSuccessApiResponse, SubmitLoginFormActionData, OnboardingModuleConfiguration, SubmitFormSuccessActionData, LoginSuccess, OnboardingState } from '../../types';
import { submitLoginFormSuccess, loginSuccess } from '../actions';
import { OnboardingAction } from '../config';
import { OnboardingEffects } from './onboarding.effects';
import { SnackbarService } from '@ca/ca-snackbar';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';
import { CaEnvironment } from '@ca/ca-utils';

@Injectable()
export class LoginEffects extends OnboardingEffects {
  /**
   * Redirects to login on logout.
   */
  private logoutObserver = {
    next: () => {
      // clear bearer token
      sessionStorage.removeItem(this.env.sessionStorageBearerKey);
      this.router.navigate([this.config.appRoutes.login]);
    },
  };

  /**
   * Sets bearer token in session storage and redirects to success route.
   */
  private loginObserver = {
    next: (res: LoginSuccess) => {
      if (res.token) this.svc.setBearerToken(res.token);
      if (res.profile && res.token) this.router.navigate([this.config.appRoutes.redirectOnSuccess]);
    },
  };

  constructor(
    @Inject(CA_ENVIRONMENT) private readonly env: CaEnvironment,
    protected override config: OnboardingModuleConfiguration,
    protected override actions$: Actions,
    protected override router: Router,
    protected override svc: OnboardingService,
    protected override logger: LoggingService,
    protected override snackbar: SnackbarService,
    protected override store: Store<{ onboarding: OnboardingState }>,
    private dialog: MatDialog
  ) {
    super(config, svc, actions$, router, snackbar, logger, store);
    this.sub.subscribe(this.actions$.pipe(ofType(OnboardingAction.LOGIN_SUCCESS)), this.loginObserver);

    this.sub.subscribe(this.actions$.pipe(ofType(OnboardingAction.LOGOUT)), this.logoutObserver);
  }

  /**
   * On Submit Login Form.
   */
  submitLoginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingAction.SUBMIT_LOGIN_FORM),
      switchMap((actionData: SubmitLoginFormActionData) =>
        this.svc.login(actionData.data).pipe(
          map((res: ILoginSuccessApiResponse) => {
            return res.success ? submitLoginFormSuccess({ response: res }) : this.QueueError(this.config.messages.login.failed);
          }),
          catchError(() => of(this.QueueError(this.config.messages.login.failed)))
        )
      )
    )
  );

  /**
   * Handles login form api response and the 2-FA dialog flow when enabled.
   */
  onSubmitLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingAction.SUBMIT_LOGIN_FORM_SUCCESS),
      switchMap((actionData: SubmitFormSuccessActionData) => {
        const res = actionData.response;
        return res.twoFaEnabled
          ? this.twoFaDialog(res)
          : of(
              loginSuccess({
                profile: res.profile,
                avatar: res.avatar,
                token: res.bearerToken,
              })
            );
      })
    )
  );

  private launchTwoFactorDialog(loginRes: ILoginSuccessApiResponse) {
    return this.dialog.open<TwoFactorAuthSetupComponent, ILoginSuccessApiResponse, boolean>(TwoFactorAuthSetupComponent, {
      width: TWO_FA_DIALOG_WIDTH, // TODO: don't use more space than needed
      data: loginRes,
    });
  }

  private mapTwoFactorDialogResult(loginRes: ILoginSuccessApiResponse, success?: boolean) {
    if (success && success == true) {
      return loginSuccess({
        profile: loginRes.profile,
        avatar: loginRes.profile?.avatar,
        token: loginRes.bearerToken,
      });
    } else return this.QueueError(this.config.messages.login.twoFaFailed);
  }

  private twoFaDialog(res: ILoginSuccessApiResponse) {
    if (this.dialog.openDialogs.length > 0) this.dialog.closeAll();
    // show 2FA dialog when enabled
    return this.launchTwoFactorDialog(res)
      .afterClosed()
      .pipe(map((success) => this.mapTwoFactorDialogResult(res, success)));
  }
}
