import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { OnboardingService } from '../../services';
import {
  OnboardingModuleConfiguration,
  GetUserProfileResponse,
  PutUpdateProfileRequest,
  IUserProfile,
  GetUsersResponse,
  GetDivisions,
  GetRoles,
  PutUpsertUserRequest,
  OnboardingState,
} from '../../types';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber } from '@ca/ca-utils';
import {
  loadDivisions,
  loadDivisionsSuccess,
  loadProfile,
  loadProfileSuccess,
  loadRoles,
  loadRolesSuccess,
  loadUsers,
  loadUsersSuccess,
  openRemoveUserModal,
  openUpsertUserModal,
  removeUser,
  removeUserSuccess,
  updateProfile,
  updateProfileSuccess,
  upsertUser,
  upsertUserSuccess,
} from '../actions';
import { switchMap, firstValueFrom, map } from 'rxjs';
import { LoggingService, NotificationFactory } from '@ca/ca-ng-core';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RemoveUserDialogComponent } from '../../components/dialog/remove-user-dialog/remove-user-dialog.component';
import { UpsertUserDialogComponent } from '../../components/dialog/upsert-user-dialog/upsert-user-dialog.component';
import { DeleteRequest } from '@ca/ca-data';
import { OnboardingEffects } from './onboarding.effects';
import { Store } from '@ngrx/store';

@Injectable()
export class UserEffects extends OnboardingEffects {
  private notificationFactory = new NotificationFactory('UserEffects');
  protected override sub = new CaSubscriber();
  private modal?: MatDialogRef<unknown>;

  constructor(
    protected override config: OnboardingModuleConfiguration,
    protected override svc: OnboardingService,
    protected override actions$: Actions,
    protected override router: Router,
    protected override snackbar: SnackbarService,
    protected override logger: LoggingService,
    protected dialog: MatDialog,
    protected override store: Store<{ onboarding: OnboardingState }>
  ) {
    super(config, svc, actions$, router, snackbar, logger, store);
    this.sub.subscribe(this.actions$.pipe(ofType(openUpsertUserModal)), {
      next: (props: IUserProfile) => {
        this.modal = this.dialog.open(UpsertUserDialogComponent, {
          data: props,
        });
      },
    });
    this.sub.subscribe(this.actions$.pipe(ofType(openRemoveUserModal)), {
      next: (props: IUserProfile) => {
        this.modal = this.dialog.open(RemoveUserDialogComponent, {
          data: props,
        });
      },
    });
  }

  onLoadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProfile),
      switchMap(() =>
        this.svc
          .getUserProfile()
          .then((value: GetUserProfileResponse) => {
            return value.success ? loadProfileSuccess({ profile: value.profile }) : this.QueueError('Could not load user profile');
          })
          .catch(() => this.QueueError('Could not load user profile'))
      )
    )
  );

  onUpdateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProfile),
      switchMap((value: { request: PutUpdateProfileRequest; current: IUserProfile }) =>
        this.svc
          .putUserProfile(value.request)
          .then((res) => (res ? updateProfileSuccess(value) : this.QueueError('Could not update user profile.')))
          .catch(() => this.QueueError('Could not update user profile.'))
      )
    )
  );

  onLoadTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUsers),
      switchMap(() =>
        this.svc
          .getUsers()
          .then((res: GetUsersResponse) => (res.success ? loadUsersSuccess({ users: res.data }) : this.QueueError('Could not load users.')))
          .catch(() => this.QueueError('Could not load users.'))
      )
    )
  );

  onLoadDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDivisions),
      switchMap(() =>
        firstValueFrom(this.svc.getDivisions())
          .then((res: GetDivisions) => (res.success ? loadDivisionsSuccess({ divisions: res.data }) : this.QueueError('Could not load divisions.')))
          .catch(() => this.QueueError('Could not load divisions.'))
      )
    )
  );

  onLoadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRoles),
      switchMap(() =>
        firstValueFrom(this.svc.getRoles())
          .then((res: GetRoles) => (res.success ? loadRolesSuccess({ roles: res.data }) : this.QueueError('Could not load roles.')))
          .catch(() => this.QueueError('Could not load roles.'))
      )
    )
  );

  onUpsertUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertUser),
      switchMap((value: PutUpsertUserRequest) =>
        firstValueFrom(this.svc.upsertUser(value))
          .then((res) => (res.success ? upsertUserSuccess({ request: value, response: res }) : this.QueueError('Could not ' + (value.id > 0 ? 'update' : 'create') + ' user.')))
          .catch(() => this.QueueError('Could not ' + (value.id > 0 ? 'update' : 'create') + ' user.'))
      )
    )
  );

  onRemoveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeUser),
      switchMap((value: DeleteRequest) =>
        firstValueFrom(this.svc.removeUser(value))
          .then((res) => (res.success ? removeUserSuccess({ request: value, response: res }) : this.QueueError('Could not remove user with id ' + value.id + '.')))
          .catch(() => this.QueueError('Could not remove user with id ' + value.id + '.'))
      )
    )
  );

  onLoadOrRemoveSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertUserSuccess, removeUserSuccess),
      map(() => {
        this.dialog.closeAll();
        return loadUsers();
      })
    )
  );
}
